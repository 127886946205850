function changeStyle(newCss) {
    var styles = document.createElement('link');

    styles.rel = "stylesheet";
    styles.setAttribute("data-link", "cssAdd");
    styles.href = "/szablony/portal/css/" + newCss + ".min.css";
    document.head.appendChild(styles);
}


function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie2(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

if (getCookie2("contrast") != "") {
    let css = getCookie2("contrast");

    document.querySelector(".settings__link[data-css=" + css + "]").setAttribute("aria-pressed", "true");
}
if (getCookie2("font") != "") {
    let css = getCookie2("font");
    document.querySelector(".settings__link[data-css=" + css + "]").setAttribute("aria-pressed", "true");
}


let settingsLinks = document.querySelectorAll(".settings__link");


for (var p = 0; p < settingsLinks.length; p++) {


    settingsLinks[p].addEventListener("click", function() {
        let btn = this;
        let group = this.getAttribute("data-group");
        let css = this.getAttribute("data-css");
       
        if (!btn.getAttribute("aria-pressed") || btn.getAttribute("aria-pressed") == "false") {
           
            if (group == "contrast") {
               
                if (document.querySelector("[data-group='contrast'][aria-pressed='true']")) {
                    let oldBtn = document.querySelector("[data-group='contrast'][aria-pressed='true']");
                    let oldCss = oldBtn.getAttribute("data-css");
                    oldBtn.removeAttribute("aria-pressed");
                    if(document.querySelector("head link[href*=" + oldCss + "][data-link='cssAdd']"))
                    {document.querySelector("head link[href*=" + oldCss + "][data-link='cssAdd']").remove();}
                }
                btn.setAttribute("aria-pressed", "true");
                changeStyle(css);
                setCookie("contrast", css, 365);

            } else {
                if (group == "font") {
                    
                    if (document.querySelector("[data-group='font'][aria-pressed='true']")) {
                        let oldBtn = document.querySelector("[data-group='font'][aria-pressed='true']");
                        let oldCss = oldBtn.getAttribute("data-css");
                        oldBtn.removeAttribute("aria-pressed");
                        if(document.querySelector("head link[href*=" + oldCss + "][data-link='cssAdd']"))
                        {document.querySelector("head link[href*=" + oldCss + "][data-link='cssAdd']").remove();}
                    }
                    btn.setAttribute("aria-pressed", "true");
                    changeStyle(css);
                    setCookie("font", css, 365);
                } else {
                    
                    if (group == "reset") {                      
                        let cssAddLinks = document.querySelectorAll("head [data-link='cssAdd']");                       
                        for (var j = 0; j < cssAddLinks.length; j++) {                        
                            cssAddLinks[j].remove();
                        }
                        let cssAddBtns = document.querySelectorAll(".settings__link[aria-pressed='true']");
                        for (var k = 0; k < cssAddBtns.length; k++) {
                            cssAddBtns[k].setAttribute("aria-pressed", "false");
                        }

                        document.cookie = "contrast=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                        document.cookie = "font=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

                    }
                }
            }
        } else {
            document.querySelector("head link[href*='" + css + "'][data-link='cssAdd']").remove();
            btn.removeAttribute("aria-pressed");
            if (group == "contrast") {
                document.cookie = "contrast=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
            }
            if (group == "font") {
                document.cookie = "font=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
            }


        }
    })
}